var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'carian.semak'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("back")))])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v("STATUS")]), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("dashboard.status")) + ":")]), _c('p', [_vm._v(" " + _vm._s(this.wasiat_status == 0 ? "Masih dalam proses pembuatan wasiat." : this.wasiat_status == 1 ? "Sedang menunggu pengesahan." : this.wasiat_status == 2 ? "Permohonan wasiat asas berjaya." : "Permohonan wasiat asas terbatal.") + " ")])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'wasiat.receipt',
        params: {
          wasiat_id: this.wasiat_id
        }
      },
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("print-receipt")) + " ")])], 1)])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("dashboard.pindaan")) + ":")]), _c('p', [_vm._v(" " + _vm._s(this.amendment_status == null ? "Anda belum membuat pindaan wasiat." : this.amendment_status == 0 ? "Anda sedang membuat pindaan wasiat, dan menunggu pengesahan." : this.amendment_status == 1 ? "Pindaan wasiat anda telah dihantar ke pihak AWARIS." : this.amendment_status == 2 ? "Pihak AWARIS telah mengesahkan Pindaan Anda, anda boleh membuat pengesahan Draf wasiat akhir." : this.amendment_status == 4 ? "Pihak AWARIS sedang mengesahkan saksi Pindaan Anda." : this.amendment_status == 5 ? "Pindaan selesai." : "-") + " ")])])])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }